import { useTranslation } from 'react-i18next';
import AboutUsLogo from './AboutUsLogo';
export default function AboutUs() {

    const { t } = useTranslation();
    return (
        <div id="about" className="position-relative">

            <div id="about-bg" className="item tw-py-10 bg-item1">
                <div className="about-font-style">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <div className="tw-mx-10">
                                        <h1 className="text-white mb-3 tw-font-bold tw-text-4xl">
                                            <b>
                                                {t("about-header")}
                                            </b></h1>
                                        <p className="lead text-white">
                                            {t("about-content")}</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <AboutUsLogo imgPath={"/img/about-img.png"} logoPath={"/img/aqua_logo_nobackground_2.png"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
