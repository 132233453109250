import { useTranslation } from 'react-i18next';
export default function Footer() {

    const { t } = useTranslation();
    return (
        <footer id="footer" className="tw-mt-20 tw-py-10 bg-item1-shrink tw-text-white">
            <div className="row">
                <div className="col-md-4 footer-column d-flex align-items-center">
                    <img src="/img/logo_white.png" alt="logo" className="mx-auto d-block" width="150" height="150" />
                </div>
                <div className="col-md-4 footer-column">
                    <ul className="nav flex-column text-center">
                        <li className="nav-item tw-font-bold mb-2">
                            <span className="footer-title">{t("AQUA System")}</span>
                        </li>
                        <li className="nav-item tw-font-light">
                            <a href="#about" >{t("About")}</a>
                        </li>
                        <li className="nav-item tw-font-light">
                            <a href="#sysIntro" >{t("Introduction")}</a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 footer-column">
                    <ul className="nav flex-column text-center">
                        <li className="nav-item tw-font-bold mb-2">
                            <span className="footer-title">{t("Team")}</span>
                        </li>
                        <li className="nav-item">
                            <a className="nav-item tw-font-light" href="#team">{t("Team Introduction")}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-item tw-font-light" href="#contactUs">{t("Contact Us")}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <hr className="tw-border-t-2 tw-border-white tw-my-10 tw-mx-auto" />

            <p className="tw-text-center tw-font-light float-end tw-mr-4">{t("rightReserved")}</p>
        </footer>

    )
}