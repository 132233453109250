import React from 'react';
import OutlinedCard2 from '../components/cards/card2'

import Footer from './footer';
import { useTranslation } from 'react-i18next';


export default function ContactUs() {

    const { t } = useTranslation();
    return (
        <div>
            <div id="contactUs" className="position-relative">
                <div className="pt-5 my-5 tw-text-center">
                    <p className="tw-font-bold tw-text-4xl tw-mb-3">
                        {t("Contact Us")}
                    </p>
                    <p className="text-secondary">
                        {t("contact-caption")}
                    </p>
                </div>
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <OutlinedCard2 iconPath="/img/cardsIcon/7.png" bodyContent="+852 3703 6123" />
                        </div>
                        <div className="col">
                            <OutlinedCard2 iconPath="/img/cardsIcon/8.png" bodyContent="contact-content-1" />
                        </div>
                        <div className="col">
                            <OutlinedCard2 iconPath="/img/cardsIcon/9.png" bodyContent="sales@JKL-Aqua.com" />
                        </div>
                        <div className="col">
                            <OutlinedCard2 iconPath="/img/cardsIcon/10.png" bodyContent="+852 6879 3384" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='position-relative tw-flex tw-justify-center'>
                <div className='container position-absolute tw-my-10 tw-h-24'>
                    <div className="tw-rounded-md tw-font-bold tw-bg-gradient-to-r tw-from-gray-400 tw-to-blue-900 tw-text-blue-950 tw-pl-8 tw-py-4 tw-text-lg">
                        {t("feature")}
                    </div>
                </div>

            </div>

            {/* footer */}
            <Footer />

        </div>

    )
}