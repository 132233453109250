import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from './locale/en.json'
import chTraditionalJSON from './locale/ch-traditional.json'
import chSimplifiedJSON from './locale/ch-simplified.json'

i18n.use(initReactI18next).init({
    resources: {
        "en": {
            translation: { ...enJSON },
        },
        "zh-Hant": {
            translation: { ...chTraditionalJSON },
        },
        "zh-CN": {
            translation: { ...chSimplifiedJSON },
        },
    },
    lng: "en",     // Set the initial language of the App
    fallbackLng: "en", // If we don't have the language in our resources, we'll fallback to this language
    interpolation: {
        escapeValue: false
    }
});