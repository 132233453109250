import { useMediaQuery } from "@uidotdev/usehooks";
import AboutUsLogo from './AboutUsLogo';
import { useTranslation } from 'react-i18next';
export default function TeamSection() {

    const { t } = useTranslation();

    const isMediumDevice = useMediaQuery(
        "only screen and (max-width : 991px)"
    );

    return (
        <div id="team" className="position-relative">

            <div id="team-bg" className="item tw-py-10 bg-item1">
                <div className="about-font-style">
                    <div className="container">
                        <div className="row">

                            {
                                !isMediumDevice && (
                                    <div className="col-lg-6 col-sm-12">
                                        <AboutUsLogo imgPath={"/img/team-img.png"} logoPath={"/img/aqua_logo_nobackground_2.png"} />
                                    </div>

                                )
                            }
                            <div className="col-lg-6 col-sm-12 mb-3">


                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <div className="tw-mx-10">
                                        <h1 className="text-white mb-3 tw-font-bold tw-text-4xl">
                                            <b>
                                                {t("team-content-1")}
                                            </b>
                                        </h1>
                                        <p className="lead text-white">
                                            {t("team-content-2")}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {
                                isMediumDevice && (
                                    <div className="col-lg-6 col-sm-12">
                                        <AboutUsLogo imgPath={"/img/team-img.png"} logoPath={"/img/aqua_logo_nobackground_2.png"} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
