import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';



export default function OutlinedCard({ iconPath, headerContent, bodyContent }) {

    const { t } = useTranslation();

    const card = (
        <React.Fragment>
            <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: 14, mb: 2 }} color="text.secondary" gutterBottom>
                    <img alt="icon" src={iconPath} className='tw-w-12 tw-h-12 tw-overflow-hidden tw-mx-auto' />
                </Typography>
                <Typography variant="h4" component="div" sx={{ mb: 2, fontWeight: 'bold' }}>
                    {t(headerContent)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t(bodyContent)}
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="none">{card}</Card>
        </Box>
    );
}