import OutlinedCard from './cards/card1'
import { useTranslation } from 'react-i18next';

export default function SysIntro() {

    const { t } = useTranslation();
    return (
        <div id="sysIntro" className="position-relative">
            <div className="pt-5 tw-mt-3 tw-mb-7 tw-text-center">
                <p className="tw-font-bold tw-text-4xl tw-mb-3">
                    {t("sysIntro")}
                </p>
                <p className="text-secondary">
                    {t("sysIntro-caption")}
                </p>
            </div>
            <div className="container d-flex justify-content-center">

                <div className="row">

                    <div className="col pb-3">
                        <OutlinedCard iconPath="/img/cardsIcon/1.png" headerContent="sysIntro-content1.1" bodyContent="sysIntro-content1.2" />
                    </div>

                    <div className="col pb-3">
                        <OutlinedCard iconPath="/img/cardsIcon/2.png" headerContent="sysIntro-content2.1" bodyContent="sysIntro-content2.2" />
                    </div>

                    <div className="col pb-3">
                        <OutlinedCard iconPath="/img/cardsIcon/3.png" headerContent="sysIntro-content3.1" bodyContent="sysIntro-content3.2" />
                    </div>

                    <div className="w-100"></div>

                    <div className="col pb-3">
                        <OutlinedCard iconPath="/img/cardsIcon/4.png" headerContent="sysIntro-content4.1" bodyContent="sysIntro-content4.2" />
                    </div>
                    <div className="col pb-3">
                        <OutlinedCard iconPath="/img/cardsIcon/5.png" headerContent="sysIntro-content5.1" bodyContent="sysIntro-content5.2" />
                    </div>
                    <div className="col pb-3">
                        <OutlinedCard iconPath="/img/cardsIcon/6.png" headerContent="sysIntro-content6.1" bodyContent="sysIntro-content6.2" />
                    </div>

                </div>
            </div>
        </div>
    )
}