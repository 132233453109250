import * as React from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from "@uidotdev/usehooks";
import { useTranslation } from 'react-i18next';

export default function AppBar() {

    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();


    const isMediumDevice = useMediaQuery(
        "only screen and (max-width : 991px)"
    );


    const handleClick = (newPlacement) => (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);

        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleChangeLang = (langCode) => {
        i18n.changeLanguage(langCode);
        setOpen(false);
    }


    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid tw-flex">
                <a className="navbar-brand" href="#logo">
                    <img src="/img/logo_black.png" alt="" width="150" height="100" />
                </a>

                {isMediumDevice && (
                    <div className="tw-ml-auto tw-px-5">
                        <a href="#contactUs" className="btn btn-danger text-white">
                            {t("Contact Us")}
                        </a>
                    </div>
                )}

                <button onClick={handleClick('bottom-end')} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                {
                    !open && (
                        <div className="collapse navbar-collapse tw-w-100" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link active p-3" aria-current="page" href="#about">{t("About")}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active p-3" aria-current="page" href="#sysIntro">{t("System Intro")}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active p-3" aria-current="page" href="#team">{t("Team Intro")}</a>
                                </li>
                            </ul>
                            <div className="d-flex p-3">
                                <span className="me-3">
                                    <a className="" type="button" onClick={() => handleChangeLang("zh-Hant")}>
                                        繁 |
                                    </a>
                                </span>
                                <span className="me-3">
                                    <a className="" type="button" onClick={() => handleChangeLang("zh-CN")}>
                                        简 |
                                    </a>
                                </span>
                                <span>
                                    <a className="" type="button" onClick={() => handleChangeLang("en")}>
                                        EN
                                    </a>
                                </span>
                            </div>
                            {!isMediumDevice && (
                                <div className="">
                                    <a href="#contactUs" className="btn btn-danger text-white">
                                        {t("Contact Us")}
                                    </a>
                                </div>
                            )}
                        </div>

                    )
                }

                <Popper
                    // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                    sx={{ zIndex: 1200 }}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                {/* <Typography sx={{ p: 2 }}>The content of the Popper.</Typography> */}
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0" onClick={() => setOpen(false)}>
                                    <li className="nav-item">
                                        <a className="nav-link active p-3" aria-current="page" href="#about">{t("About")}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active p-3" aria-current="page" href="#sysIntro">{t("System Intro")}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active p-3" aria-current="page" href="#team">{t("Team Intro")}</a>
                                    </li>
                                </ul>
                                <div className="d-flex p-3">
                                    <span className="me-3">
                                        <a className="" type="button" onClick={() => handleChangeLang("zh-Hant")}>
                                            繁 |
                                        </a>
                                    </span>
                                    <span className="me-3">
                                        <a className="" type="button" onClick={() => handleChangeLang("zh-CN")}>
                                            简 |
                                        </a>
                                    </span>
                                    <span>
                                        <a className="" type="button" onClick={() => handleChangeLang("en")}>
                                            EN
                                        </a>
                                    </span>
                                </div>

                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </nav>
    );
}
