import './App.css';
import AppBar from './components/appBar.js';
import AboutUs from './components/about.js';
import TeamSection from './components/team.js';
import ContactUs from './components/contactUs.js';
import SysIntro from './components/sysIntro.js';

function App() {
  return (
    <div>
      <AppBar />
      <AboutUs />
      <SysIntro />
      <TeamSection />
      <ContactUs />
    </div>
  );
}

export default App;
